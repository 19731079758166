import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import {  Alert, message, message as messages, Popconfirm } from 'antd';
import PaginationComponent from "../../Common/PaginationComponent";
import MasterModal from "../../Common/MasterModal";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import MasterHeader from "../../Common/MasterHeader";
import LoaderCommon from "../../Common/LoaderCommon";
import InputUseForm from "../../Common/InputUseForm";
import AutoCompleteInput from "../../Common/AutoCompleteInput";
import InputCheckBox from "../../Common/InputCheckBox";
import InputSelectForm from "../../Common/InputSelectForm";
import { generateValidationSchema } from "../../Common/validationSchema";
import toast from "react-hot-toast";
import InputTextArea from "../../Common/InputTextArea";
import eventQueries from "../../../queries/eventQueries";
import moment from "moment";
import { useApi } from "../../Common/ApiProvider";

const EventsList =()=>{

    const [result, setResult] = useState([]);
    const [name, setName] = useState('')
    const [country, setCountry] = useState([])
    const [college, setCollege] = useState([])
    const [office, setOffice] = useState([])
    const [loader, setLoader] = useState(true)
    const master = userStore();

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const { apiData, setApiDataAndNotify } = useApi()

    const inputFields = [
        {
            name: "country_id",
            label: "Country",
            placeholder:"Country",
            size: "3",
            type: AutoCompleteInput,
            required: true,
            options: country,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "university_id",
            label: "University",
            placeholder:"University",
            size: "3",
            type: AutoCompleteInput,
            required: true,
            options: college,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "office_id",
            label: "Office",
            placeholder:"Office",
            size: "3",
            type: AutoCompleteInput,
            required: false,
            options: office,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "name",
            label: "Event Name",
            placeholder:"Event Name",
            size: "3",
            min: 2,
            max: 250,
            type: InputUseForm,
            required: true
        },
        {
            name: "date",
            label: "Date",
            placeholder:"Date",
            size: "3",
            iptype: "date",
            type: InputUseForm,
            required: true
        },
        {
            name: "startTime",
            label: "Start Time",
            placeholder:"Start Time",
            size: "3",
            iptype: "time",
            type: InputUseForm,
            required: true
        },
        {
            name: "endTime",
            label: "End Time",
            placeholder:"End Time",
            size: "3",
            iptype: "time",
            type: InputUseForm,
            required: true
        },
        {
            name: "type",
            label: "Type",
            placeholder:"Type",
            size: "3",
            type: InputSelectForm,
            required: true,
            options:[{name:"Delegate Visit", value: "DELEGATE"}, {name:"Seminar", value: "SEMINAR"}, {name:"Webinar", value: "WEBINAR"}],
            optionProps:{name: "name", name2: "", value: "value", value2: ""}
        },
        {
            name: "venue",
            label: "Venue",
            placeholder:"Venue",
            size: "6",
            min: 2,
            max: 250,
            type: InputUseForm,
            required: true
        },
        {
            name: "details",
            label: "Details",
            placeholder:"Details",
            size: "6",
            min: 2,
            max: 250,
            type: InputTextArea,
            required: true
        },
    ]

    const { register ,reset, handleSubmit, formState:{ errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    const listing = eventQueries.useEventList(
        async(response) => {
            setResult(response)
            setLoader(false)           
        }   
    );

    const dropdown = eventQueries.useMasterDropdown(
        async(response) => {
            setCountry(response?.country)
            setCollege(response?.college)
            setOffice(response?.office)
            setLoader(false)           
        }   
    );

    useEffect(() => {
        dropdown.mutateAsync({size: 500000})
    }, []);

    useEffect(()=>{
        listing.mutateAsync({search: name, page:currentPage, size:25});
    },[name, currentPage, apiData])

    const deleteEvent = eventQueries.useEventDelete(
        (response) => {
            setApiDataAndNotify(response)
            toast.success("Successfully deleted")
        }
    );

    const deleteMaster = (event, id) => {
        event.preventDefault();
        deleteEvent.mutateAsync(id)
    }

    const [visible, setVisible] = useState(false)

    const handleOpen =async(item)=>{
        if(!item){
            await master.setMaster(null)
            setVisible(true)
            return 
        }else{
            reset(item)
            await master.setMaster(item.id)
            setVisible(true)
        }
    }

    const handleClose =async()=>{
        const getvalue = getValues()
        for(const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
              setValue(key, "")
            }
        }
        clearErrors()
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null)
    }

    const createMaster = eventQueries.useEventCreate(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                setApiDataAndNotify(response)
                toast.success("Successfully created")
            }
        }   
    );
        
    const updateMaster = eventQueries.useEventUpdate(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        toast.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            setApiDataAndNotify(response)
            toast.success("Successfully updated")
        }   
    );


    return(
        <Fragment>
            <div>	
                <MasterHeader handleOpen={handleOpen} header="Events" breadcrumb="Events" />			
				<div className="add_clients">
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Events</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
                            <div className="card-header text-[14px]">
                                Events
                            </div>
		    				<div id="appointment_any" className="collapse show" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-md-12">
                                        
                                            <div className="table-list-search-wrpr">
                                                <div className="row">
                                                    <div className="col-md-6" />
                                                    <div className="col-md-6 text-right">
                                                        <div className="table-search-filter-wrpr">
                                                            <div className="table-search-wrpr">
                                                                <input style={{minWidth: '300px'}} onChange={(e)=>{setName(e.target.value)}} className="form-control form-control-sm" type="text" placeholder="Search Event" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                          
                                            {
                                                loader ?
                                                <LoaderCommon />:
                                                <div className="table-wrpr">
                                                    <div className="table-section">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="table prospect-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="5%">#</th>
                                                                            <th>Date</th>
                                                                            <th>Day</th>
                                                                            <th>Time</th>
                                                                            <th>Event</th>
                                                                            <th>Country</th>
                                                                            <th>Venue</th>
                                                                            <th>Type</th>
                                                                            <th>Details</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="ui-sortable">
                                                                        {
                                                                            result?.data?.items?.map((item, index) =>(
                                                                            <tr className="ui-sortable-handle" key={index} >
                                                                                <th className="">{(index + 1) + result?.data?.currentPage * 25}</th>
                                                                                <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                                                                                <td>{moment(item?.date).format('dddd')}</td>
                                                                                <td>{moment(item?.startTime, 'hh:mm').format('hh:mm A')} - {moment(item?.endTime, 'hh:mm').format('hh:mm A')}</td>
                                                                                <td>{item?.name}</td>
                                                                                <td>{item?.country?.icon} {item?.country?.name}</td>
                                                                                <td>{item?.venue}</td>
                                                                                <td>{item?.type}</td>
                                                                                <td>{item?.details}</td>
                                                                                <td className="action-icons flex gap-2 justify-end">                                                     
                                                                                    <button onClick={()=>{handleOpen(item)}} className="btn-nextrow mb-0">Update</button>
                                                                                    <Popconfirm
                                                                                        placement="left"
                                                                                        title="Are you sure to delete this event?"
                                                                                        onConfirm={(event) => {deleteMaster(event, item.id)}}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button className="btn-removerow mb-0">Delete</button>
                                                                                    </Popconfirm>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <MasterModal
                                            width="70%"
                                            header="Event"
                                            visible={visible} 
                                            handleClose={handleClose} 
                                            inputFields={inputFields} 
                                            reset={reset} 
                                            control={control}
                                            handleSubmit={handleSubmit} 
                                            register={register} 
                                            errors={errors} 
                                            getValues={master?.master}
                                            defaultValues={getValues()}
                                            create={createMaster}
                                            update={updateMaster}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
				</div>
			</div>
        </Fragment>
    )
}

export default EventsList